import React from "react";
import { Helmet } from "react-helmet";
import "../styles/index.scss";

const Layout = ({ children }) => (
  <>
    <Helmet
      title="TheBronxDirect"
      meta={[
        {
          property: "description",
          content: "The Bronx Direct is a digital directory (and job board) that connects professionals, influencers, and community organizations in the Bronx."
        },
        { property: "og:title", content: "The Bronx Direct" },
        {
          property: "og:description",
          content:
            "The Bronx Direct is a digital directory (and job board) that connects professionals, influencers, and community organizations in the Bronx."
        },
        {
          property: "og:image",
          content: "https://thebronx.direct/opengraph.png"
        },
        {
          property: "og:url",
          content: "https://thebronx.direct"
        },
        { property: "og:type", content: "website" },
        { property: "og:site_name", content: "The Bronx Direct" },
        { property: "twitter:site", content: "@thebronxdirect" },
        { property: "twitter:creator", content: "@bdfeliz" },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:image",
          content: "https://thebronx.direct/opengraph.png"
        }
      ]}
    />
    {children}
  </>
);

export default Layout;
